import { useFetcher as useRemixFetcher } from "@remix-run/react";
import usePrevious from "@tamarack/shared/hooks/usePrevious";
import type { AppUrl } from "../AppUrl";

export type Fetcher<T> = ReturnType<typeof useFetcher<T>>;

export default function useFetcher<T>(opts?: Parameters<typeof useRemixFetcher>[0]) {
  const fetcher = useRemixFetcher<T>(opts);
  const previousNavigationState = usePrevious(fetcher.state);

  const isActionSubmission = fetcher.state === "submitting";
  const isActionReload =
    fetcher.state === "loading" && fetcher.formMethod != null && fetcher.formMethod === "POST";

  const submitted =
    (previousNavigationState === "loading" || previousNavigationState === "submitting") &&
    fetcher.state === "idle";
  const submitting = isActionSubmission || isActionReload;

  return {
    fetcher,
    submitted,
    loaded: submitted,
    loading: fetcher.state === "loading",
    submitting,
    Form: fetcher.Form,
    data: fetcher.data,
    load: (url: string | AppUrl) => {
      return fetcher.load(url.toString());
    },
    submit: fetcher.submit,
    idle: fetcher.state === "idle",
    neverLoaded: fetcher.state === "idle" && !fetcher.data,
  };
}
